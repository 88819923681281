<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vue3-datepicker";
// import { ref } from "vue";
import $ from "jquery";

export default {
    page: {
        title: "Tambah Penyusunan Materi Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Datepicker,
    },
    data() {
        return {
            title: "Edit Draft Penyusunan Materi Uji Kompetensi",
            items: [
                {
                    text: "Validasi MUK",
                    href: "/validasi-muk",
                },
                {
                    text: "Tambah Penyusunan Materi Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            id : this.$route.params.id,

            // variabel referensi
            optionsSkemaSertifikasi: [],
            optionsMetodeSertifikasi: [],
            optionsDokumen: [],

            // variabel page
            skema_sertifikasi_selected: null,
            metode_sertifikasi_selected: null,
            statusListDokumen: false,

            //modal
            showModalSimpan: false,
            role_id: localStorage.getItem("session_role_active_id"),
            backend_url: process.env.VUE_APP_BACKEND_URL_VERSION,

            statusSimpanHeaderSuratTugas: false,
            // Variable Master
            master_konfig_dokumen: [],
            master_skema: ["Kepala Departemen Uji Klinis", "Manager Uji Klinis", "Staff Uji Klinis", "Staff Muda Uji Klinis"],

            // variable input
            urutan_nomor: null,
            nomor_surat_tugas: null,
            tanggal_surat_tugas: null,
            asesor_penyusun: null,
            perihal: "Surat Tugas Penyusunan Materi Uji Kompetensi",
            skema: null,
            preview_surat_tugas: null,
            modalPreviewDokumenMUK: false,
            formPreviewDocMUK: null,
            loadingTable: false,
            data_surat_tugas: [],
            penyusunan_id: null,
        };
    },
    mounted() {
        let self = this;
        self.getDetailPenyusunan();
        self.getDataSkemaSertifikasi();
        self.getDataMetodeSertifikasi();
        // self.getDokumenSkemaMetode('cee5e014-3757-11ee-9cda-0242ac110008','99defda6-5868-43e7-bbf4-3975df6b17ae')
    },
    methods: {
        getDetailPenyusunan() {
            let self = this;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/show",
                params: {
                    id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.data_penyusunan = data_edit;
                        var data_dokumen = data_edit.penyusunan_muk_dokumen;
                        // var data_asesor = data_edit.penyusunan_muk_asesor;
                        self.hak_akses = response_data.hak_akses;
                        self.is_show_dokumen_muk = response_data.is_show_dokumen_muk;
                        self.jumlah_dokumen_isi = response_data_fix.jumlah_dokumen_isi;
                        self.nomor_surat_tugas = response_data_fix.surat_penyusunan_nomor;
                        self.tanggal_surat_tugas = new Date(response_data_fix.surat_penyusunan_tanggal);

                        self.loadingTable = false;
                        self.data_timeline = data_edit.penyusunan_muk_timeline;
                        if (data_dokumen) {
                            let clear_data_data_dokumen = [];
                            var jumlah_dokumen_wajib_isi = 0;
                            $.each(data_dokumen, function (indexInArray, valueOfElement) {
                                clear_data_data_dokumen.push({
                                    penyusunan_dokumen: valueOfElement,
                                    dokumen: valueOfElement.dokumen,
                                    catatan: null,
                                });
                            });
                            self.optionsDokumen = clear_data_data_dokumen;
                            self.jumlah_dokumen_wajib_isi = jumlah_dokumen_wajib_isi;
                        }
                        // console.log(self.optionsDokumen);
                        // self.skk.url = data_edit.skk_url;
                        // self.skema_sertifikasi_dokumen.url = data_edit.skema_asertifikasi_dokumen_url;
                        // self.skk.nama = data_edit.skk_nama;
                        // self.dokumen_all = self.dokumen_isian;
                        (self.preview_surat_tugas = process.env.VUE_APP_BACKEND_URL + "/" + data_edit.path_surat_tugas);
                        self.simpanHeaderSuratTugas();
                        self.statusListDokumen = true;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getDataSkemaSertifikasi() {
            let self = this;

            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                        self.skema_sertifikasi_selected = self.optionsSkemaSertifikasi.find((x) => x.id_skema_sertifikasi == self.data_penyusunan.skema_id);
                
                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDataMetodeSertifikasi() {
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-metode-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsMetodeSertifikasi = response.data.data.referensi;
                        self.metode_sertifikasi_selected = self.optionsMetodeSertifikasi.find((x) => x.id_metode_sertifikasi == self.data_penyusunan.metode_sertifikasi_id);
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getNomorSurat() {
            let self = this;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/get-nomor-surat", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    self.nomor_surat_tugas = next_nomor + "/MUK/" + currentDate.getMonth() + "/" + currentDate.getFullYear();
                    self.urutan_nomor = next_nomor;
                })
                .catch((e) => {
                    console.log(e.response);
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        storeSuratTugas() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.nomor_surat_tugas == "" || self.nomor_surat_tugas == null || self.tanggal_surat_tugas == "" || self.tanggal_surat_tugas == null || self.skema_sertifikasi_selected == "" || self.skema_sertifikasi_selected == null || self.metode_sertifikasi_selected == "" || self.metode_sertifikasi_selected == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Formulir Terisi Lengkap",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/store-surat-tugas",
                        {
                            nomor_surat_tugas: self.nomor_surat_tugas,
                            tanggal_surat_tugas: self.tanggal_surat_tugas,
                            asesor_penyusun: self.asesor_penyusun,
                            perihal: self.perihal,
                            skema_id: self.skema_sertifikasi_selected?.id_skema_sertifikasi,
                            skema_code: self.skema_sertifikasi_selected?.kode_skema_sertifikasi,
                            skema_nama: self.skema_sertifikasi_selected?.nama_skema_sertifikasi,
                            metode_sertifikasi_id: self.metode_sertifikasi_selected?.id_metode_sertifikasi,
                            metode_sertifikasi_nama: self.metode_sertifikasi_selected?.nama_metode_sertifikasi,
                            urutan_nomor: self.urutan_nomor,
                            path_surat_tugas: self.preview_surat_tugas,
                            list_dokumen: JSON.stringify(self.optionsDokumen),
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        console.log(response);
                        var res = response.data.data;
                        if (res?.status == "error") {
                            Swal.fire({
                                icon: "error",
                                title: res.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Surat Tugas Berhasil Disimpan",
                                text: "Surat Tugas Akan Segera Ditampilkan",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    self.preview_surat_tugas = res.pdf;
                                    self.data_surat_tugas = res.data_surat_tugas;
                                    self.optionsDokumen = res.dokumen_surat_tugas;
                                    self.statusListDokumen = true;
                                    self.simpanHeaderSuratTugas();
                                }
                            });
                        }
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },
        submitSuratTugas(status) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var send_status = "cb7775b4-3f6d-11ee-bd7c-0242ac110009";
            
            if (status == "ajukan") {
                send_status = "1406ab18-3c0f-11ee-a5ac-0242ac110009";
            }

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/ubah-status",
                data: {
                    id: self.id,
                    next_status: send_status,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list penyusunan muk segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalValidasi = false;
                            self.showModalAsesor = false;
                            self.$router.push({ name: "all-daftar_muk" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        getFormDokumenMUK(index, dokumen_id) {
            let self = this;
            self.formPreviewDocMUK = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/preview-dokumen-muk", {
                    params: {
                        penyusunan_id: self.data_surat_tugas.id,
                        dokumen_id: dokumen_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.formPreviewDocMUK = res_fix.data.pdf;
                    console.log(res_fix);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });

            self.modalPreviewDokumenMUK = true;
        },
        StorePreviewMUK() {},

        addDokumen() {
            this.master_konfig_dokumen.push({
                nama_dokumen: "",
                keterangan: null,
                tanggal: null,
            });
        },
        removeDokumen(index) {
            this.master_konfig_dokumen.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        StoreData() {
            let self = this;
            Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Tunggu Sebentar",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.close();
                    self.showModalSimpan = false;
                    self.$router.push({ name: "konfirmasi-kehadiran_muk" });
                }
            });
        },
        simpanHeaderSuratTugas() {
            let self = this;
            self.statusSimpanHeaderSuratTugas = true;
        },
        getDokumenList() {
            let self = this;
            var skema_id = self.skema_sertifikasi_selected?.id_skema_sertifikasi;
            var metode_id = self.metode_sertifikasi_selected?.id_metode_sertifikasi;
            self.statusListDokumen = false;
            if (skema_id && metode_id) {
                self.getDokumenSkemaMetode(skema_id, metode_id);
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Formulir Surat Tugas Penyusunan Materi Uji Kompetensi</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mt-3">
                                            <div class="form-group">
                                                <label for="tipe_menu"> Nomor Surat Tugas <span class="text-danger">*</span></label>
                                                <input type="text" v-model="nomor_surat_tugas" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div class="form-group">
                                                <label for="tipe_menu">Tanggal Surat Tugas <span class="text-danger">*</span></label>
                                                <datepicker v-model="tanggal_surat_tugas" lang="en" class="form-control bg-white"></datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div class="form-group">
                                                <label for="tipe_menu">Pilih Skema Sertifikasi <span class="text-danger">*</span></label>
                                                <v-select :options="optionsSkemaSertifikasi" label="nama_skema_sertifikasi" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div class="form-group">
                                                <label for="tipe_menu">Perihal</label>
                                                <input type="text" v-model="perihal" id="" class="form-control" readonly />
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div class="form-group">
                                                <label for="tipe_menu">Pilih Metode Sertifikasi <span class="text-danger">*</span></label>
                                                <v-select :options="optionsMetodeSertifikasi" label="nama_metode_sertifikasi" v-model="metode_sertifikasi_selected" placeholder="Pilih Metode Sertifikasi"></v-select>
                                            </div>
                                        </div>
                                        <!-- v-if="role_id != '99c668b7-015c-4b68-9761-d2393129d070'" -->

                                        <div class="col-md-12 mt-3 text-end">
                                            <button class="btn btn-primary" @click="storeSuratTugas">
                                                <i class="fas fa-save"></i>
                                                Simpan Surat Tugas Penyusunan MUK
                                            </button>
                                        </div>
                                    </div>
                                    <div class="alert alert-info mt-3" v-if="!statusListDokumen"><i class="fas fa-info"></i> Simpan Surat Tugas Penyusunan Terlebih Dahulu</div>
                                    <div class="row" v-if="preview_surat_tugas">
                                        <h3>Preview Surat Tugas</h3>
                                        <div class="row p-4">
                                            <div class="card" style="outline-style: outset">
                                                <div class="card-body">
                                                    <iframe v-bind:src="preview_surat_tugas" width="100%" height="400"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" v-if="statusListDokumen">
                                        <label for="tipe_menu">Dokumen MUK</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Dokumen</th>
                                                    <th>Preview</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="loadingTable">
                                                    <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                </tr>
                                                <tr v-else-if="optionsDokumen.length == 0">
                                                    <td colspan="4">Dokumen Tidak Tersedia</td>
                                                </tr>
                                                <tr v-else v-for="(item, index) in optionsDokumen" :key="index">
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>
                                                        {{ item.dokumen?.nama_dokumen_muk }}
                                                    </td>
                                                    <td>
                                                        <button type="button" v-on:click="getFormDokumenMUK(index, item.dokumen.id)" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> Preview Dokumen</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row" v-if="statusSimpanHeaderSuratTugas">
                                <div class="col-md-12 col-md-6 mt-3">
                                    <div class="text-end">
                                        <!-- <router-link
                                            :to="{
                                                name: 'detail-daftar_muk_penyusunan',
                                                params: { id: 1 },
                                            }"
                                            class="btn btn-primary bg-primary m-1"
                                            ><i class="fas fa-save"></i> Submit</router-link
                                        > -->
                                        <button v-on:click="submitSuratTugas('draft')" class="btn btn-warning" type="button"><i class="fas fa-edit"></i> Simpan Draft</button>
                                        <button class="btn btn-primary bg-primary m-1" v-on:click="submitSuratTugas('ajukan')"><i class="fas fa-save"></i> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="showModalSimpan" title="Konfigurasi Atasan" title-class="text-black font-18" body-class="p-3" size="xl" dialog-class="modal-90" hide-footer>
        <div class="row">
            <div class="col-12">
                <form @submit.prevent="StoreData">
                    <div class="form-group">
                        <label for="tipe_menu">Manajer Sertifikasi</label>
                        <select name="" id="" class="form-control">
                            <option value="">Pilih Manajer Sertifikasi</option>
                            <option value="">Manajer Sertifikasi 1</option>
                            <option value="">Manajer Sertifikasi 2</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="tipe_menu">Manajer Pelatihan</label>
                        <select name="" id="" class="form-control">
                            <option value="">Pilih Manajer Pelatihan</option>
                            <option value="">Manajer Pelatihan 1</option>
                            <option value="">Manajer Pelatihan 2</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="tipe_menu">Ketua TUK</label>
                        <select name="" id="" class="form-control">
                            <option value="">Pilih Ketua TUK</option>
                            <option value="">Ketua TUK 1</option>
                            <option value="">Ketua TUK 2</option>
                        </select>
                    </div>

                    <div class="col-md-12 text-end mt-3">
                        <button class="btn btn-primary" type="submit"><i class="fas fa-save"></i> Simpan</button>
                    </div>
                </form>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="modalPreviewDokumenMUK" title="Preview Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <form @submit.prevent="StorePreviewMUK">
                <!-- <div v-html="formPreviewDocMUK"></div> -->

                <iframe v-bind:src="formPreviewDocMUK" width="100%" height="400"></iframe>
                <!-- <div class="card-footer text-end">
                    <button class="btn btn-primary"><i class="fas fa-times"></i> Tutup</button>
                </div> -->
            </form>
        </div>
    </b-modal>
</template>
